import { unpack } from "msgpackr";
import "./styles.css";

import PartySocket, { WebSocket } from "partysocket";
const dev = PARTYKIT_HOST === "127.0.0.1:1999";

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.

/*
const conn = new PartySocket({
  host:
    window.location.port != ""
      ? PARTYKIT_HOST
      : "socketproxy.nautit.workers.dev",
  query: async () => ({
    // get an auth token using your authentication client library
    [`socketKey_token_${window.location.hostname}`]: document.cookie
      .split(";")
      .find((c) =>
        c.trim().startsWith(`socketKey_token_${window.location.hostname}`)
      )
      ?.split("=")[1],
  }),
  //room: window.location.hostname,
}); */
const conn_url = new URL(
  dev ? `ws://${PARTYKIT_HOST}/party/main` : "wss://socket.nut.fi"
);
const cookie = document.cookie
  .split(";")
  .find((c) =>
    c.trim().startsWith(`socketKey_token_${window.location.hostname}`)
  )
  ?.split("=")[1];
if (cookie)
  conn_url.searchParams.set(
    `socketKey_token_${window.location.hostname}`,
    cookie
  );
const conn = new WebSocket(conn_url.toString());

function setCookie(data: any) {
  document.cookie =
    `socketKey_token_${window.location.hostname}` +
    "=" +
    data.token +
    ";path=/";
}
const handleEventData = async (data: any) => {
  console.log("data", data);
  if (data.token) setCookie(data);
  if (data.type === "init") {
    //conn.updateProperties({ id: data.id });
    //add("ID: " + conn.id);
  }
};
// You can even start sending messages before the connection is open!
conn.addEventListener("message", async (event) => {
  const data =
    event.data instanceof Blob
      ? // byte array -> msgpack
        handleEventData(unpack(await event.data.arrayBuffer()))
      : add(`Received -> ${event.data}`);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  add("Connected!");
  add("Sending a ping every 2 seconds...");
  // TODO: make this more interesting / nice
  clearInterval(pingInterval);
  pingInterval = setInterval(() => {
    conn.send("ping");
  }, 2000);
});
